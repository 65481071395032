@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600');
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600');

html {
  overflow-y: scroll;
}

body, input {
  margin: 0;
  font-family: "Montserrat";
  font-size: 11px;
  letter-spacing: 0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lock-activity-action {
  flex: 1;
}
.action-icon {
  width: 18px;
  max-height: 18px;
  padding: 0 8px 4px 0;
  vertical-align: middle;
  filter: opacity(30%);
}
.action-icon-remote-unlock {
  width: auto;
  margin: 0 4px;
  filter: invert(50%) opacity(50%);
}
.compactToolbar {
  min-height: auto !important
}
.compactToolbar .MuiIconButton-root {
  padding: 4px;
}

/*
https://docs.amplify.aws/ui/customization/customizing-css/q/framework/react 
SAMPLE ASK, for below: https://github.com/aws-amplify/amplify-js/issues/6752
https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/components/amplify-form-section/readme.md
SAMPLE IMPLEMENTATION of AmplifyFormSection: https://github.com/aws-amplify/amplify-js/issues/5298#issuecomment-612638892
RYO: https://github.com/aws-amplify/amplify-js/issues/8010#issuecomment-753313188
*/

:root {
  --amplify-primary-color: #00969d;
  --amplify-primary-tint: green;
  --amplify-primary-shade: green;
  /* --amplify-white: transparent; */
}


/* :root .section {
  background: red;
} */

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  /* background-color: red; */
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.squarespace-cdn.com/content/v1/5b075626506fbe9b5aba78ed/1559261768545-27USVUEAXVDOOUWCRY2Y/ke17ZwdGBToddI8pDm48kH29ZTyws1X0y1-htsih8-l7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UW2OpHjWwyUeAxEKDBsqn7eKS5fAaxMqUsTUWjFJA6yQMW9u6oXQZQicHHG1WEE6fg/Nexkey-Core-Web.png?format=2500w);
}

amplify-form-section {
  background-color: red;
}
:host(.section) {
  background-color: blue;
}



/* 
amplify-section {
  --footer-color: red;
  --background-color: green;
  background-color: red;
}

amplify-sign-in :root amplify-form-section {
  --footer-color: red;
  --background-color: green;
  background-color: red;
}

amplify-sign-in amplify-form-section amplify-section {
  --footer-color: red;
  --background-color: green;
  background-color: red;
} */

/* amplify-form-section {
  background-color: transparent;
  --background-color: green;
} */

/* amplify-form-section {
  --background-color: transparent;
  --amplify-background-color: transparent;
} */

